import React, { useState, useEffect } from 'react';
import '../Css/HomePicture-Css.css';

import homeImage1 from '../Imgs/couples1.jpg';
import homeImage2 from '../Imgs/couples2.jpg';
import homeImage3 from '../Imgs/couples3.jpg';

const images = [homeImage1, homeImage2, homeImage3];
const textOverlay = "A Blend of artistry, and creative vision frames our shots with innovation";

const HomePicture = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="home-picture" style={{ backgroundImage: `url(${images[currentImageIndex]})` }}>
      <div className="text-container">
        <span className="text">{textOverlay}</span>
      </div>
    </div>
  );
};

export default HomePicture;
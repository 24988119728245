import React, { useEffect, useRef, useState } from 'react';
import '../Css/weddingDetail.css';
import { useParams } from 'react-router-dom';
import wedding1 from '../Imgs/wedding1.jpg';
import wedding2 from '../Imgs/wedding2.jpg';
import wedding3 from '../Imgs/wedding3.jpg';
import wedding4 from '../Imgs/wedding4.jpg';
import engagement1 from '../Imgs/engagement1.jpg';
import engagement2 from '../Imgs/engagement2.jpg';
import couples1 from '../Imgs/couples1.jpg';
import couples2 from '../Imgs/couples2.jpg';
import couples3 from '../Imgs/couples3.jpg';

const weddings = [
  { id: 1, bride: 'Alice', groom: 'Bob', coverImage: wedding1, images: [wedding1, wedding2, wedding3, wedding4, engagement1, engagement2, couples1, couples2, couples3, wedding2, wedding3] },
  { id: 2, bride: 'Claire', groom: 'Dan', coverImage: wedding2, images: [wedding1, wedding2, wedding3, wedding4, engagement1, engagement2, couples1, couples2, couples3, wedding2, wedding3] },
  { id: 3, bride: 'Eve', groom: 'Frank', coverImage: wedding3, images: [wedding1, wedding2, wedding3, wedding4, engagement1, engagement2, couples1, couples2, couples3, wedding2, wedding3] },
  { id: 4, bride: 'Pam', groom: 'Georges', coverImage: wedding4, images: [wedding1, wedding2, wedding3, wedding4, engagement1, engagement2, couples1, couples2, couples3, wedding2, wedding3] },
  { id: 5, bride: 'Rita', groom: 'Frank', coverImage: engagement1, images: [wedding1, wedding2, wedding3, wedding4, engagement1, engagement2, couples1, couples2, couples3, wedding2, wedding3] },
  { id: 6, bride: 'Monda', groom: 'Mar', coverImage: engagement2, images: [wedding1, wedding2, wedding3, wedding4, engagement1, engagement2, couples1, couples2, couples3, wedding2, wedding3] },
];

const WeddingDetail = () => {
  const { id } = useParams();
  const wedding = weddings.find(w => w.id === parseInt(id));
  const topRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
    // Focus on the top element
    topRef.current && topRef.current.focus();
  }, []);

  if (!wedding) return <div>Wedding not found</div>;

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = (e) => {
    if (e.target.className === 'image-modal' || e.target.className === 'close-button') {
      setSelectedImage(null);
    }
  };

  return (
    <div className="wedding-detail">
      <div className="cover-photo" style={{ backgroundImage: `url(${wedding.coverImage})` }}>
        <div className="bride-groom-names">
          <h2>{wedding.bride} & {wedding.groom}</h2>
        </div>
      </div>
      <div className="images-grid">
        {wedding.images.map((image, index) => (
          <img key={index} src={image} alt={`Wedding ${index}`} onClick={() => handleImageClick(image)} />
        ))}
      </div>

      {selectedImage && (
        <div className="image-modal" onClick={handleClose}>
          <span className="close-button" onClick={handleClose}>×</span>
          <img src={selectedImage} alt="Selected" className="modal-content" />
        </div>
      )}
    </div>
  );
};

export default WeddingDetail;


import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from './Pages/JavaScript/HomePage';
import WeddingDetail from './Pages/JavaScript/WeddingDetail.js';
function App() {
  return (
        <Router>
          <div className="App">
            <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/wedding/:id" element={<WeddingDetail />} /> 
            {/* Add more routes for other pages */}
            </Routes>
          </div>
        </Router>
  );
}

export default App;


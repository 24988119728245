import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '../Css/footer-css.css'; 
import { FacebookIcon, InstagramIcon } from './SocialIcons.js';
import logoImage from '../Imgs/logo.png';
import PrivacyPolicyModal from './PrivacyPolicyModal';

const Footer = () => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const handlePrivacyPolicyClose = () => setShowPrivacyPolicy(false);
  const handlePrivacyPolicyShow = () => setShowPrivacyPolicy(true);

  return (
    <footer className="bg-dark text-white py-4">
      <div className="container">
        <div className="row mb-4">
          <div className="col-md-3 col-sm-12 text-center mb-4 mb-md-0">
            <div className="logo">
              <img src={logoImage} alt="Logo" className="logo-img" />
            </div>
          </div>
          <div className="col-md-3 col-sm-12 text-center mb-4 mb-md-0">
            <h5>Follow Us</h5>
            <div className="d-flex justify-content-center">
              <a href="https://www.facebook.com/marounabdelnourphotography/" target="_blank" rel="noopener noreferrer" className="me-3">
                <FacebookIcon />
              </a>
              <a href="https://www.instagram.com/marounabdelnour/" target="_blank" rel="noopener noreferrer">
                <InstagramIcon />
              </a>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 text-center mb-4 mb-md-0">
            <h5>Contact Us</h5>
            <p>
              <strong>Beirut:</strong> +961 3540050<br />
              <strong>Dubai:</strong> +971 557423371
            </p>
          </div>
          <div className="col-md-3 col-sm-12 text-center">
            <a href="#" onClick={handlePrivacyPolicyShow} className="text-white">Privacy Policy</a>
            <p className="mb-0">© 2023 All rights reserved.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <h5>Report a Problem</h5>
            <p className="your-paragraph-class">
              If you encounter any issues, please{' '}
              <a href="mailto:info@marounabdelnour.com">contact support</a>.
            </p>
          </div>
        </div>
      </div>
      <PrivacyPolicyModal show={showPrivacyPolicy} handleClose={handlePrivacyPolicyClose} />
    </footer>
  );
};

export default Footer;

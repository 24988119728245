// src/components/HomePage.js
import React, {useState } from 'react';
import HomePicture from './HomePicture';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap styles
import Footer from './FotterPage';

const ContactPage = React.lazy(() => import('./ContactPage'));
const AboutUs = React.lazy(() => import('./AboutUs'));
const WeddingsContainer = React.lazy(() => import('./WeddingsContainer'));
const NavBar = React.lazy(() => import('./NavBar'));

const HomePage = () => {
  return (
    <div className="home-container">
      <div id="home-section">
      <NavBar />
      <HomePicture />
      </div>
      <div id="about-section">
        <AboutUs />
      </div>
      <div id="weddings-section">
        <WeddingsContainer />
      </div>
      <div id="contact-section">
        <ContactPage />
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;